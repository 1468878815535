import Head from 'next/head';
import Link from 'next/link';
import Image from "next/image";
import React, { useState,useEffect,useRef } from "react";

const services = [
    {
        title: 'SEO Strategie & Analytics',
        description: 'Eine Kampagne ohne klare Ziele, die eine umfassende SEO-Strategie informieren, wird nicht die notwendige Kraft haben, um eine Website an die Spitze der Suchergebnisse zu bringen. Unsere SEO-Strategie- und Analytics-Services umfassen alles von der strategischen Zielsetzung über das Projektmanagement bis hin zur Einrichtung von Google Analytics und der Verfolgung von Zielen.',
        icon: '📈',
    },
    {
        title: 'Keyword-Recherche-Services',
        description: 'Die Keyword-Recherche steht im Mittelpunkt jeder Kampagne. Sie treibt die Strategie für die Priorisierung von Seiten und die Zuweisung von Kampagnenressourcen an. Dieser Bereich umfasst alles von der Identifizierung zentraler Keyword-Themen bis hin zur Erweiterung der thematischen Autorität. Eine fortlaufende Keyword-Recherche baut Schwung für erfolgreiche Kampagnen auf.',
        icon: '🔑',
    },
    {
        title: 'SEO-Audit-Services',
        description: 'Im Gegensatz zu anderen SEO-Marketingfirmen führen wir keinen Audit durch, ohne zuvor umfangreiche Keyword-Recherche durchzuführen. Warum? Ein durch Recherche informierter Audit konzentriert sich genau auf die Ziele einer spezifischen SEO-Kampagne. Diese Service-Kategorie umfasst alles von einem technischen Audit mit über 200 Punkten bis hin zur Backlink-Analyse und internationalen SEO.',
        icon: '🕵️‍♀️',
    },
    {
        title: 'On-Page-SEO-Services',
        description: 'Manchmal auch "On-Site"-Services genannt, umfasst diese Disziplin alle Suchoptimierungen, die direkt auf den Seiten einer Website implementiert werden. Diese Art von SEO umfasst Optimierungen von Meta-Tags, Verbesserung der Conversion-Rate und Optimierung von Featured Snippets.',
        icon: '🔧',
    },
    {
        title: 'Off-Page-SEO-Services',
        description: 'Auch als "Off-Site"-SEO bezeichnet, umfasst diese Kategorie Aktivitäten zur Verbesserung der Suchleistung, die nicht direkt auf einer Website implementiert werden. Beispiele hierfür sind Linkaufbau, Optimierung von Google My Business und App Store Optimization.',
        icon: '🌐',
    },
    {
        title: 'SEO-Content-Services',
        description: 'Wenn SEO das Fahrzeug ist, das organischen Traffic generiert, dann ist SEO-Content der Treibstoff. Diese Art von Content unterscheidet sich von Ihren allgemeinen Content-Marketing-Aktivitäten. Unsere Content-Angebote als SEO-Beratungsagentur umfassen Dienstleistungen wie die Erstellung von SEO-Content, die Aufwertung vorhandenen Contents und strategische Briefings für Ihr Schreibteam.',
        icon: '🖋',
    },
];

function ServiceCard({service}) {
    return (
        <div className="w-full md:w-1/3 p-3">
            <div className="bg-white dark:bg-gray-800 shadow-lg rounded-lg p-6">
                <div className="text-4xl mb-3">{service.icon}</div>
                <h3 className="text-2xl font-bold">{service.title}</h3>
                <p className="text-gray-600 dark:text-gray-400">{service.description}</p>
            </div>
        </div>
    );
}
const embedID = "stHBZGm_hMo";

export default function Home() {
    const [imageClicked, setImageClicked] = useState(false);
    const onThumbnailClick = () => {
        setImageClicked(true);
    };

    useEffect(()=>{
        const playImg = document.querySelector("#play-button");

        playImg.addEventListener("click",onThumbnailClick,{once:true});
    },[])

    const schemaMarkup = JSON.stringify({
        "@context": "https://schema.org",
        "@type": "WebSite",
        "url": "https://www.deinseoexpert.de/",
        "name": "deinSEOExpert",
        "description": "Professionelle SEO-Dienstleistungen und Beratung - deinSEOExpert"
    });

    return (
        <div className="flex flex-col min-h-screen bg-gray-100 dark:bg-gray-900">
            <Head>
                <title>deinSEOExpert</title>
                <link rel="icon" href="/favicon.ico" sizes="any"/>
                <meta
                    name="description"
                    content="Ihr SEO Experte für bessere Platzierung in Google-Suchergebnissen. Besuchen Sie deinSEOExpert und buchen Sie unverbindlich einen Beratungstermin."
                />
                <meta property="og:title" content="deinSEOExpert.de"/>
                <meta
                    property="og:description"
                    content="Entdecken Sie meine professionellen SEO-Dienstleistungen auf deinSEOExpert. Besuchen Sie meine Website für kompetente Beratung und Expertenwissen im SEO-Bereich."
                />
                <meta
                    property="og:image"
                    content="/OGImage_deinseoexpertde.webp"
                />
                <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: schemaMarkup }} />
                <link rel="canonical" href="https://www.deinseoexpert.de/" />
            </Head>
            <div className="relative h-[90vh] md:h-[80vh] lg:h-screen flex items-center">
                <Image
                    src="/seoBackground.webp"
                    fill={true}
                    objectFit="cover"
                    objectPosition="left"
                    className="lg:object-center"
                    alt="SEO Background"
                    quality={100}
                    priority={true}
                   />
                <div className="absolute left-4 sm:left-8 md:left-10 lg:left-10 right-4 sm:right-8 md:right-10 lg:right-10 z-10 w-11/12 sm:w-10/12 md:w-8/12 lg:max-w-[35%]">
                    <h1 className="text-5xl md:text-6xl lg:text-7xl text-blue-900 font-bold">Dein SEO Experte</h1>
                    <p className="text-white mt-4 mb-8 text-sm md:text-base lg:text-lg">
                        Erfolg im Internet beginnt mit der richtigen SEO-Strategie. Als professioneller SEO-Experte biete ich umfassende SEO-Dienstleistungen an, die Ihr Ranking verbessern und den Traffic auf Ihre Website erhöhen.
                    </p>
                    <p className="text-white mt-4 mb-8 text-sm md:text-base lg:text-lg">
                        Ich führe gründliche Keyword-Recherchen und Wettbewerbsanalysen durch, um Ihre Website optimal zu positionieren. Zudem bin ich spezialisiert auf SEO-Audits, Backlink-Optimierung und On-Page-SEO, um sicherzustellen, dass Ihre Website den besten ROI erzielt.
                    </p>
                    <Link href="/kontakt" legacyBehavior>
                        <button className="relative px-8 py-4 bg-blue-900 text-white font-bold rounded hover:bg-blue-700 shadow-button">Kontaktieren Sie mich</button>
                    </Link>
                </div>
            </div>

            <section className="flex flex-wrap md:flex-nowrap justify-center p-6 md:px-24 gap-12 md:gap-24">
                <div className="w-full md:w-1/2 text-left md:text-center mb-8 md:mb-0">
                    <h2 className="text-4xl mb-4 font-semibold">Ihr Unternehmen braucht einen SEO Experten</h2>
                    <p className="text-lg">
                        Um die gewünschten Ergebnisse zu erzielen, ist es wichtig, das richtige Gleichgewicht zwischen
                        der Auswahl von Schlüsselwörtern, Leistungszielen, Kampagnenbudget und Zeitrahmen zu finden.
                        Viele der Schlüsselwörter mit dem größten Potenzial für die Steigerung von Traffic und
                        Konversionen sind stark umkämpft. Für kleine Unternehmen, mittelgroße Unternehmen und sogar
                        große Unternehmen ist es daher effektiver, eine höhere Anzahl von mittelgroßen und
                        Long-Tail-Schlüsselwörtern anzustreben, bei denen der Wettbewerb nicht so stark ist, aber das
                        Potenzial für Konversionen dennoch hoch ist.
                    </p>
                </div>

            <div className="w-full md:w-1/2">
                    <div className="video rounded-lg shadow-xl">
                        {!imageClicked ? (
                            <>
                                <Image
                                    src="/maxresdefault.jpg"
                                    layout="fill"
                                    objectFit="cover"
                                    alt="yt thumbnail"
                                    priority
                                />
                                <Image id="play-button" className="play" src="/7.png" alt="play button"  width={150}
                                       height={150}/>
                            </>
                        ) : (
                            <iframe
                                allowFullScreen
                                src={
                                    imageClicked
                                        ? `https://www.youtube.com/embed/${embedID}?rel=0&showinfo=0&autoplay=1`
                                        : ""
                                }
                                title="youtube video"
                            />
                        )}
                    </div>
                </div>
            </section>

            <section className="container mx-auto px-6 md:px-24 py-20">
                <h2 className="text-2xl text-left md:text-center text-gray-500">WIE WIR ARBEITEN</h2>
                <h3 className="text-4xl font-bold text-left md:text-center">So optimieren wir Ihre Website für SEO</h3>
                <p className="mt-4 text-left md:text-center">
                    Nach einem ausführlichen Beratungsgespräch, in dem ich den Umfang und die Anforderungen Ihrer Website für eine optimale Suchmaschinenoptimierung bespreche, beginne ich mit den folgenden Schritten.
                    Ihr Zeitaufwand beträgt insgesamt nur 2 Stunden!
                </p>
            </section>

            <section className="container mx-auto px-24 custom-container-bg">
                <div className="container mx-auto py-16">
                    <div className="grid grid-cols-1 md:grid-cols-2">
                        <div>
                            <div className="md:w-4/5 mx-auto">
                                <Image
                                    src="/technischeOptimierung.png"
                                    alt="copywriting min"
                                    width={800}
                                    height={488}
                                />
                            </div>
                        </div>
                        <div className="flex items-center">
                            <div className="w-20">
                                <div className="circle">
                                    <span className="text-white font-bold text-lg">1</span>
                                </div>
                            </div>
                            <div className="ml-4">
                                <div className="container-text">
                                    <h4 className="font-bold text-2xl">Inhalte, Struktur und technische Optimierung</h4>
                                    <p>
                                        Ich arbeite an einzigartigem Inhalt, Lesbarkeit für Crawler, Navigation und interner Verlinkung, URL-Struktur, Titel & Description, HTML-Überschriften h1 - h6, HTML-Tags, Fehlermeldungen, Sitemaps und Serveranpassungen.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container mx-auto py-16">
                    <div className="grid grid-cols-1 md:grid-cols-2">
                            <div className="flex items-center">
                                <div className="w-20">
                                    <div className="circle">
                                        <span className="text-white font-bold text-lg">2</span>
                                    </div>
                                </div>
                                <div className="ml-4">
                                    <h4 className="font-bold text-2xl">Keyword-Recherche und Anwendung</h4>
                                    <p>
                                        Ich führe Keywordanalysen durch, optimiere Keywords, passe Seitentitel und Texte an Keywords an und achte auf die Keyworddichte.
                                    </p>
                                </div>
                            </div>
                        <div>
                            <div className="md:w-4/5 mx-auto">
                                <Image
                                    src="/Keyword-Recherche.png"
                                    alt="Keyword Recherche"
                                    width={700}
                                    height={535}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mx-auto py-16">
                    <div className="grid grid-cols-1 md:grid-cols-2">

                        <div>
                            <div className="md:w-4/5 mx-auto">
                                <Image
                                    src="/backlink.png"
                                    alt="backlink"
                                    width={600}
                                    height={392}
                                />
                            </div>
                        </div>

                            <div className="flex items-center">
                                <div className="w-20">
                                    <div className="circle">
                                        <span className="text-white font-bold text-lg">3</span>
                                    </div>
                                </div>
                                <div className="ml-4">
                                    <h4 className="font-bold text-2xl">Backlink-Strategie</h4>
                                    <p>
                                        Ich entwickle eine Backlink-Strategie und setze auf den Aufbau externer Verlinkungen. Zudem entwickle ich eine Content-Strategie.
                                    </p>
                                </div>
                            </div>
                    </div>
                </div>
                <div className="container mx-auto py-16 relative">
                    <div className="grid grid-cols-1 md:grid-cols-2">
                            <div className="flex items-center">
                                <div className="w-20">
                                    <div className="circle">
                                        <span className="text-white font-bold text-lg">4</span>
                                    </div>
                                </div>
                                <div className="ml-4">
                                    <h4 className="font-bold text-2xl">SocialMedia</h4>
                                    <p>
                                        Ich biete auch die Erstellung automatisierter Beiträge an und unterstütze bei der Planung und Umsetzung von Social-Media-Prozessen.
                                    </p>
                                </div>
                            </div>
                        <div>
                            <div className="md:w-4/5 mx-auto">
                                <Image
                                    src="/social.png"
                                    alt="device mockup 044 min min"
                                    width={600}
                                    height={392}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="container mx-auto px-6 py-20">
                <h2 className="text-4xl font-bold text-center mb-10">Meine Dienstleistungen</h2>
                <div className="flex flex-wrap">
                    {services.map((service, index) => (
                        <ServiceCard key={index} service={service}/>
                    ))}
                </div>
            </section>

            <section className="flex flex-wrap justify-center items-center p-6 px-12">
                <h2 className="text-4xl mb-4 w-full">Kunden, die mir vertrauen</h2>
                <div className="m-4">
                    <Link href="https://mexecution.com/" legacyBehavior>
                        <Image
                            src="companyLogo/mex.svg"
                            alt="Logo Mexecution"
                            width={150} height={150} objectFit="cover"/>
                    </Link>
                </div>

                <div className="m-4">
                    <Link href="https://wodoil.at" legacyBehavior>
                        <Image
                            src="/companyLogo/WODOIL-LOGO-DUNKELBLAU.png"
                            alt="Logo Wodoil"
                            width={150}
                            height={150} objectFit="cover"/>
                    </Link>
                </div>

                <div className="m-4">
                    <Link href="https://www.beziehungsberatung-hannover.de/" legacyBehavior>
                        <Image
                            src="/companyLogo/cropped-PNG-2-160x117.png"
                            alt="Logo beziehungsberatung hannover"
                            width={150}
                            objectFit="cover"
                            height={150}/>
                    </Link>
                </div>

                <div className="m-4">
                    <Link href="https://the-scientist-shop.com/" legacyBehavior>
                        <Image src="/companyLogo/logoo.png" alt="Logo the scientist shop"
                               width={150} height={150} objectFit="cover"/>
                    </Link>
                </div>
                <div className="m-4">
                    <Link href="https://repro-gmbh.com" legacyBehavior>
                        <Image src="/companyLogo/RePro-Logo-180g.png" alt="Logo repro gmbh"
                               width={150} height={150} objectFit="cover"/>
                    </Link>
                </div>

                <div className="m-4">
                    <Link href="https://www.gutachter-fronius.de" legacyBehavior>
                        <Image src="/companyLogo/Logo_Fronius_R_ohne_Slogan.svg" alt="Logo Fronius"
                               width={150} height={150} objectFit="cover"/>
                    </Link>
                </div>
                <div className="m-4">
                    <Link href="https://www.betterhabit.de" legacyBehavior>
                        <Image src="/companyLogo/betterhabit_logo.webp" alt="Logo betterhabit"
                               width={150} height={150} objectFit="cover"/>
                    </Link>
                </div>
            </section>
        </div>
    );
}

